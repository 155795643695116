{
    "Heisse Preise": "Daglige priser",
    "page_description": "page_description",
    "Einstellungen": "Indstillinger",
    "Impressum": "Imprint",
    "Logs": "Logs",
    "Historische Daten von": "Historiske data fra",
    "Alle Angaben ohne Gewähr, Irrtümer vorbehalten.": "Den givne information er uden garanti, der kan forekomme fejl.",
    "Markennamen und Warenzeichen sind Eigentum der jeweiligen Inhaber.": "Mærkenavne og er de respektive ejeres ejendom.",
    "Suche": "Søg",
    "Preisänderungen": "Prisændringer",
    "Warenkörbe": "Indkøbskurve",
    "Noch keine Produkte im Warenkorb.": "Din indkøbskurv er tom.",
    "Produkte suchen und mit '+' zum Warenkorb hinzufügen.": "Søg efter varer og tilføj dem til indkøbskurven med '+'",
    "Filtern...": "Filter...",
    "(min. 3 Zeichen)": "(mindst 3 tegn)",
    "Produkt hinzufügen...": "Tilføj vare...",
    "Neuer Warenkorb": "Ny indkøbskurv",
    "Exportieren": "Eksporter",
    "Importieren": "Importer",
    "Medieninhaber": "Ejer",
    "Kontakt": "Kontakt",
    "Adresse": "Addresse",
    "Diese nicht-kommerzielle Seite dient KonsumentInnen dazu, Preise von Produkten im Lebensmittelhandel vergleichen zu können.": "Denne ikke-kommercielle side tilbyder forbrugere at sammenligne priser i supermarkedet.",
    "Video Anleitung": "Videoinstruktioner (på tysk)",
    "Text Anleitung": "Tekstinstruktioner (på tysk)",
    "Medienberichte": "Media reports",
    "Produktsuche": "Produkt søgning",
    "Radio & Fernsehen": "Radio & Television",
    "Print & Online": "Print & Online",
    "CartsList_Name": "Navn",
    "CartsList_Produkte": "Vare",
    "CartsList_Preis": "Pris",
    "CartsList_Preisänderungen": "Prisændringer",
    "CartsList_Teilen": "Del",
    "CartsList_JSON": "JSON",
    "CartsList_Löschen": "Fjern",
    "ItemsChart_Keine Daten ausgewählt": "Ingen data valgt",
    "ItemsChart_Preissumme Gesamt": "Totalpris",
    "ItemsChart_Preissumme Ketten": "Butikspris",
    "ItemsChart_Nur heutige Preise": "Dagspris",
    "ItemsChart_Änderung in % seit": "Ændring i % siden",
    "ItemsChart_Änderung in % seit {{date}}": "Ændring i % siden {{date}}",
    "ItemsChart_Preissumme {{s}}": "Pris {{s}}",
    "ItemsFilter_Produkte suchen...": "Varesøgning...",
    "ItemsFilter_Filter anzeigen/ausblenden": "Vis/gem filter",
    "ItemsFilter_Alle": "Alle",
    "ItemsFilter_Datum": "Dato",
    "ItemsFilter_Billiger seit letzter Änderung": "Billigere siden sidste ændring",
    "ItemsFilter_Nur Diskont-Eigenmarken": "Kun discount-supermarkeder",
    "ItemsFilter_Nur Bio": "Kun øko",
    "ItemsFilter_Exaktes Wort": "Eksakt ord",
    "ItemsFilter_Preis €": "Pris (kr)",
    "ItemsFilter_Teurer": "Dyrere",
    "ItemsFilter_Billiger": "Billigere",
    "ItemsFilter_Quantity changes": "🤏",
    "ItemsList_Resultate": "Resultater",
    "ItemsList_Diagramm": "Diagram",
    "ItemsList_Verkaufspreis": "Salgspris",
    "ItemsList_Mengenpreis": "Enhedspris",
    "ItemsList_Sortieren": "Sorter efter",
    "ItemsList_Preis aufsteigend": "Pris, stigende",
    "ItemsList_Preis absteigend": "Pris, faldende",
    "ItemsList_Menge aufsteigend": "Mængde, stigende",
    "ItemsList_Menge absteigend": "Mængde, faldende",
    "ItemsList_Kette &amp; Name": "Butikskæde &amp; navn",
    "ItemsList_Namensähnlichkeit": "Navnelighed",
    "ItemsList_Kette": "Butikskæde",
    "ItemsList_Name": "Navn",
    "ItemsList_Preis": "Pris",
    "Cart_Teilen": "Del",
    "Cart_Speichern": "Gem",
    "Cart_Warenkorb {{name}}": "Indkøbskurv {{name}}",
    "Cart_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu speichernden Warenkorb eingeben": "Indkøbskurven '{{name}}' eksisterer allerede. Vælg venligst et andet navn, så indkøbskurven kan gemmes",
    "Cart_Warenkorb '{{name}}' existiert nicht.": "Indkøbskurven '{{name}}' eksisterer ikke.",
    "Cart_Artikel": "Artikel",
    "Carts_Name für Warenkorb eingeben:": "Skriv navn på indkøbskurv:",
    "Carts_Warenkorb mit Namen '{{name}}' existiert bereits": "Indkøbskurven '{{name}}' eksisterer allerede",
    "Carts_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu importierenden Warenkorb eingeben": "Indkøbskurven '{{name}}' eksisterer allerede. Vælg venligst et andet navn så indkøbskurven kan importeres",
    "Settings_Vorselektierte Ketten": "Forvalgte butikskæder",
    "Settings_Start-Datum für Diagramme": "Startdato for diagram",
    "Settings_Diagramm Typ": "Diagramtype",
    "Settings_Stufen": "Stiplet",
    "Settings_Linien": "Linjer",
    "Settings_Nur verfügbare Produkte anzeigen": "Vis kun tilgængelige varer",
    "Settings_Diagramm immer anzeigen (wenn verfügbar)": "Vis altid diagram (når muligt)",
    "Settings_Suche immer anzeigen (wenn verfügbar)": "Vis altid søgefelt (når muligt)"
}
