const allSpacesRegex = / /g;

exports.stores = {
    // billa: {
    //     name: "Billa",
    //     budgetBrands: ["clever"],
    //     color: "yellow",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://shop.billa.at/produkte/${item.url}`,
    // },
    // spar: {
    //     name: "Spar",
    //     budgetBrands: ["s-budget"],
    //     color: "green",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.interspar.at/shop/lebensmittel/p/${item.id}`,
    // },
    // hofer: {
    //     name: "Hofer",
    //     budgetBrands: ["milfina"],
    //     color: "purple",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.roksh.at/hofer/produkte/${item.url}`,
    // },
    // mpreis: {
    //     name: "MPREIS",
    //     budgetBrands: [],
    //     color: "rose",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.mpreis.at/shop/p/${item.id}`,
    // },
    // dm: {
    //     name: "DM",
    //     budgetBrands: ["balea"],
    //     color: "orange",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.dm.at/product-p${item.id}.html`,
    // },
    // mueller: {
    //     name: "Müller",
    //     budgetBrands: ["aveo"],
    //     color: "emerald",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.mueller.at${item.url}`,
    // },
    // unimarkt: {
    //     name: "Unimarkt",
    //     budgetBrands: ["jeden tag", "unipur"],
    //     color: "blue",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://shop.unimarkt.at/${item.url}`,
    // },
    // penny: {
    //     name: "Penny",
    //     budgetBrands: ["bravo", "echt bio!", "san fabio", "federike", "blik", "berida", "today", "ich bin österreich"],
    //     color: "purple",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.penny.at/produkte/${item.url}`,
    //     removeOld: true,
    // },
    // bipa: {
    //     name: "Bipa",
    //     budgetBrands: ["babywell", "look by bipa", "bi care", "bi kids", "bi good", "bi life", "bi life dent"],
    //     color: "rose",
    //     defaultChecked: true,
    //     getUrl: (item) => `https://www.bipa.at${item.url}`,
    //     removeOld: false,
    // },
    // dmDe: {
    //     name: "DM DE",
    //     budgetBrands: ["balea"],
    //     color: "teal",
    //     defaultChecked: false,
    //     getUrl: (item) => `https://www.dm.de/product-p${item.id}.html`,
    // },
    // reweDe: {
    //     name: "REWE DE",
    //     budgetBrands: ["ja!"],
    //     color: "stone",
    //     defaultChecked: false,
    //     getUrl: (item) => `https://shop.rewe.de/p/${item.name.toLowerCase().replace(allSpacesRegex, "-")}/${item.id}`,
    // },
    // sparSi: {
    //     name: "Spar SI",
    //     budgetBrands: ["s-budget"],
    //     color: "emerald",
    //     defaultChecked: false,
    //     getUrl: (item) => `https://www.spar.si/online/p/${item.id}`,
    // },
    // muellerDe: {
    //     name: "Müller DE",
    //     budgetBrands: ["aveo"],
    //     color: "stone",
    //     defaultChecked: false,
    //     getUrl: (item) => `https://www.muller.de/${item.url}`,
    // },
    spar: {
        name: "Spar",
        budgetBrands: [],
        color: "green",
        defaultChecked: true,
        getUrl: (item) => `https://glostrup.spar.dk${item.url}`,
    },
    meny: {
        name: "Meny",
        budgetBrands: [],
        color: "red",
        defaultChecked: true,
        getUrl: (item) => `https://roenne.meny.dk${item.url}`,
    },
    minkobmand: {
        name: "Min Købmand",
        budgetBrands: [],
        color: "white",
        defaultChecked: true,
        getUrl: (item) => `https://toksvard.minkobmand.dk${item.url}`,
    },
    lidl: {
        name: "Lidl",
        budgetBrands: ["milbona", "alpengut", "cien", "livarno", "wiesentaler"],
        color: "pink",
        defaultChecked: true,
        getUrl: (item) => `https://www.lidl.dk${item.url}`,
        removeOld: true,
    },
    rema1000: {
        name: "REMA 1000",
        budgetBrands: [],
        color: "blue",
        defaultChecked: true,
        getUrl: (item) => `https://shop.rema1000.dk${item.url}`,
    },
    nemlig: {
        name: "Nemlig",
        budgetBrands: [],
        color: "orange",
        defaultChecked: true,
        getUrl: (item) => `https://nemlig.dk${item.url}`,
    },
    fotex: {
        name: "Føtex",
        budgetBrands: [],
        color: "deebblue",
        defaultChecked: true,
        getUrl: (item) => `https://føtex.dk`,
    },
    netto: {
        name: "Netto",
        budgetBrands: [],
        color: "yellow",
        defaultChecked: true,
        getUrl: (item) => `https://netto.dk`,
    },
    bilkatogo: {
        name: "BilkaToGo",
        budgetBrands: [],
        color: "cyan",
        defaultChecked: true,
        getUrl: (item) => `https://www.bilkatogo.dk${item.url}`,
    },
};

exports.STORE_KEYS = Object.keys(exports.stores);
exports.BUDGET_BRANDS = [...new Set([].concat(...Object.values(exports.stores).map((store) => store.budgetBrands)))];
